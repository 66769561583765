import { ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";

import { IUnit } from "../../types/units";

// renders a comma-delimited line of strings (as in addresses), filtering no value
const renderAddressLine = (parts: string[]) =>
  parts.filter((v) => v).join(", ");

type UnitListProps = {
  handleListItemClick: (code: string) => void;
  itemCount: number;
  rowHeight: number;
  units: IUnit[];
};

export const UnitList: React.FC<UnitListProps> = ({
  handleListItemClick,
  itemCount,
  rowHeight,
  units,
}) => {
  const renderer = React.useMemo(() => newRenderer(handleListItemClick), [
    handleListItemClick,
  ]);

  const listItemKey = (index: number, data: UnitListData) => {
    const unit = data.units[index];
    return unit ? unit.code : index;
  };

  return (
    <List
      width="100%"
      height={rowHeight * 10}
      itemCount={itemCount}
      itemData={{ units: units }}
      itemKey={listItemKey}
      itemSize={72}
    >
      {renderer}
    </List>
  );
};

type UnitListData = {
  units: IUnit[];
};

const newRenderer = (handleListItemClick: (code: string) => void) =>
  React.memo(function ListRenderer({
    index,
    style,
    data,
  }: ListChildComponentProps) {
    const unit = data.units[index];
    if (!unit) {
      return null;
    }

    return (
      <ListItem
        button
        style={style}
        key={index}
        onClick={() => handleListItemClick(unit.code)}
      >
        <ListItemText
          primary={renderAddressLine([unit.address.line1, unit.address.line2])}
          secondary={renderAddressLine([
            unit.address.line3,
            unit.address.line4,
            unit.address.line5,
          ])}
        />
      </ListItem>
    );
  });
