import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import SendIcon from "@material-ui/icons/Send";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "final-form-material-ui";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";

import { useRegistrationAPI } from "../../../lib/hooks";
import { ITenant } from "../../../types/tenants";

type FormContext = {
  emailAddress: string;
};

type InviteModalBodyProps = {
  onClose: () => void;
  onSubmit: (arg1: { emailAddress: string }) => void;
  submitting: boolean;
};

const InviteModalBody: React.FunctionComponent<InviteModalBodyProps> = ({
  onClose,
  onSubmit,
  submitting,
}) => {
  const initialValues: FormContext = {
    emailAddress: "",
  };

  const handleSubmit = (values: FormContext) => {
    onSubmit(values);
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <DialogContentText>
            To invite a third party to read the details in your portal, please
            enter their email address below. We will send the party an
            invitation link which they can click to establish their own profile.
          </DialogContentText>
          <Field
            component={TextField}
            autoFocus
            margin="dense"
            id="emailAddress"
            name="emailAddress"
            type="email"
            fullWidth
            label="Third party's email address"
            required
          />
          <Typography variant="caption">
            You can remove access at any time.
          </Typography>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={pristine || invalid || submitting}
              startIcon={<SendIcon />}
              type="submit"
              variant="contained"
            >
              Invite
            </Button>
          </DialogActions>
        </form>
      )}
    />
  );
};

type InviteDialogProps = {
  onClose: () => void;
  tenant: ITenant;
};

export const InviteDialog: React.FunctionComponent<
  InviteDialogProps & DialogProps
> = ({ onClose, tenant, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<Error>();

  const handleClose = () => {
    setSubmitting(false);
    setSubmitError(undefined);
    onClose();
  };

  React.useEffect(() => {
    return () => {
      setSubmitting(false);
      setSubmitError(undefined);
    };
  }, []);

  const registrationAPI = useRegistrationAPI();

  const handleSubmit = async function ({ emailAddress }: FormContext) {
    setSubmitting(true);
    setSubmitError(undefined);

    try {
      const invite = await registrationAPI.createInviteForTenant(
        tenant.code,
        true
      );
      await registrationAPI.sendInviteNotificationToTenant(
        {
          inviteID: invite.inviteID,
          inviteToken: invite.inviteToken,
        },
        emailAddress
      );
      handleClose();
    } catch (err) {
      setSubmitError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      aria-labelledby="invite-modal-title"
      fullScreen={fullScreen}
      onClose={onClose}
      {...props}
    >
      <DialogTitle id="invite-modal-title">Invite third-party user</DialogTitle>
      <DialogContent>
        <InviteModalBody
          onClose={onClose}
          onSubmit={handleSubmit}
          submitting={submitting}
        />
        {submitError && (
          <Alert severity="error">
            An error was encountered while inviting the third party:
            {submitError.message}
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};
