import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Add as AddIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useCallback, useEffect, useState } from "react";

import { usePortalAPI } from "../../../lib/hooks";
import { UserProfile } from "../../../types/profiles";
import { ITenant } from "../../../types/tenants";
import ContentBox from "../../../widgets/content-box";
import { ThirdPartyProfilesGrid } from "./grid";
import { InviteDialog } from "./invite-modal";

type Props = {
  tenant: ITenant;
};

export const ThirdPartyProfilesBox: React.FunctionComponent<Props> = ({
  tenant,
}) => {
  const api = usePortalAPI();
  const [profiles, setProfiles] = useState<UserProfile[]>([]);
  const [, setLoadProfilesError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);
  const handleInviteModalClose = () => {
    setInviteModalOpen(false);
  };

  React.useEffect(() => {
    return () => {
      setInviteModalOpen(false);
    };
  }, []);

  const loadUserProfiles = useCallback(
    async function () {
      setLoading(true);

      try {
        const profiles = await api.listTenantUserProfiles(tenant.code);
        setProfiles(profiles);
      } catch (err) {
        setLoadProfilesError(err);
      } finally {
        setLoading(false);
      }
    },
    [api, tenant]
  );

  useEffect(() => {
    async function load() {
      await loadUserProfiles();
    }

    load();
  }, [loadUserProfiles]);

  const text =
    profiles.length > 0 ? (
      <>
        You have provided <strong>read-only</strong> access to the portal to the
        following individuals:
      </>
    ) : (
      <>
        You may provide <strong>read-only</strong> access to the portal to other
        named individuals. Get started below.
      </>
    );

  const [, setDeleteError] = useState<Error>();

  const handleDelete = async function ({ username }: { username: string }) {
    setDeleteError(undefined);

    try {
      await api.deleteTenantUserProfile(tenant.code, username);
      await loadUserProfiles();
    } catch (err) {
      setDeleteError(err);
    }
  };

  return (
    <ContentBox title="Third-party profiles">
      <Typography gutterBottom>{loading ? <Skeleton /> : text}</Typography>
      {profiles.length > 0 && (
        <ThirdPartyProfilesGrid
          onDelete={handleDelete}
          profiles={profiles}
          primaryEmailAddress={tenant.contactDetails.emailMain}
        />
      )}
      <Grid container justify="flex-end">
        <Fab
          aria-label="add"
          color="primary"
          onClick={() => {
            setInviteModalOpen(true);
          }}
          variant="extended"
        >
          <AddIcon />
          Invite Third-party
        </Fab>
      </Grid>
      <InviteDialog
        onClose={handleInviteModalClose}
        open={inviteModalOpen}
        tenant={tenant}
      />
    </ContentBox>
  );
};
