import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedUnit } from "../../actions/portal";
import { doLoadUnits } from "../../actions/units";
import { RootState } from "../../reducers";
import { selectUnits } from "../../reducers/units";
import { IUnit } from "../../types/units";
import { UnitSelectLayout } from "./layout";
import { UnitList } from "./list";
import { useUnitSelectionStyles } from "./styles";

function unitMatcher(searchTerm: string) {
  return (u: IUnit) =>
    [
      u.address.line1,
      u.address.line2,
      u.address.line3,
      u.address.line4,
      u.address.line5,
      u.search,
    ]
      .filter((v) => v)
      .some((d) =>
        d.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      );
}

const unitSort = (a: IUnit, b: IUnit) =>
  a.search.localeCompare(b.search, undefined, { sensitivity: "base" });

function filterUnits(units: IUnit[], searchTerm: string) {
  return units.filter(unitMatcher(searchTerm));
}

const UnitSelectionContainer: React.FunctionComponent = () => {
  const classes = useUnitSelectionStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doLoadUnits.request());
  }, [dispatch]);

  const units = useSelector(selectUnits);
  const unitsAreLoading = useSelector(
    (state: RootState) => state.units.loading
  );

  // If there is only one unit, send the user straight there without the select page.
  React.useEffect(() => {
    if (units.length === 1) {
      dispatch(setSelectedUnit(units[0].code));
    }
  }, [dispatch, units]);

  const hasUnits = units.length > 0;
  const hasMultipleUnits = units.length > 1;

  const [searchTerm, setSearchTerm] = React.useState("");
  const onSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
  };
  const onSelectUnit = (code: string) => {
    dispatch(setSelectedUnit(code));
  };

  const filteredUnits = filterUnits(units, searchTerm);
  filteredUnits.sort(unitSort);

  if (unitsAreLoading) {
    return (
      <UnitSelectLayout>
        <Grid container alignItems="center" direction="column">
          <Grid item xs>
            <CircularProgress />
          </Grid>
          <Grid item xs>
            <Typography component="h4" variant="h6" align="center">
              Loading units. Please wait.
            </Typography>
          </Grid>
        </Grid>
      </UnitSelectLayout>
    );
  }

  if (!hasUnits) {
    return (
      <UnitSelectLayout>
        <Typography component="h4" variant="h6" align="center">
          We have no units registered for you. Please contact Esskay Property
          Management Services.
        </Typography>
      </UnitSelectLayout>
    );
  }

  const rowHeight = 72;

  return (
    <UnitSelectLayout showMultipleUnitsBanner={hasMultipleUnits}>
      <div className={classes.unitSearchWidget}>
        <TextField
          fullWidth
          id="unit-search"
          label="Search"
          onChange={onSearchChange}
          placeholder="Mount Eden Road"
          variant="outlined"
        />
      </div>

      <Paper>
        <div className={classes.unitList}>
          <UnitList
            handleListItemClick={onSelectUnit}
            itemCount={filteredUnits.length}
            rowHeight={rowHeight}
            units={filteredUnits}
          />
        </div>
      </Paper>
    </UnitSelectLayout>
  );
};

export { UnitSelectionContainer };
