import { Container, Typography } from "@material-ui/core";
import React from "react";

import { useUnitSelectionStyles } from "./styles";

type UnitSelectLayoutProps = React.PropsWithChildren<{
  showMultipleUnitsBanner?: boolean;
}>;

const UnitSelectLayout: React.FunctionComponent<UnitSelectLayoutProps> = ({
  children,
  showMultipleUnitsBanner,
}) => {
  const classes = useUnitSelectionStyles();

  return (
    <>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          Select unit
        </Typography>
        {showMultipleUnitsBanner && (
          <Typography align="center">
            We have multiple units registered for you. Please select the unit to
            administer.
          </Typography>
        )}
      </Container>
      <Container
        maxWidth="sm"
        component="main"
        className={classes.unitListContainer}
      >
        <>{children}</>
      </Container>
    </>
  );
};

export { UnitSelectLayout };
