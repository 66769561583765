import { AxiosInstance, AxiosResponse } from "axios";
import Moment from "moment";

import { IAccountEntry } from "../../types/account";
import { ContactMessage } from "../../types/contact";
import { IDocument } from "../../types/documents";
import { IInvoice } from "../../types/invoice";
import { INews } from "../../types/news";
import { UserProfile } from "../../types/profiles";
import { IProperty } from "../../types/property";
import {
  ITenant,
  ITenantAddressDetails,
  ITenantContactDetails,
  ITenantEmailDetails,
  ITenantRoles,
  ITenantTelephoneDetails,
} from "../../types/tenants";
import { IUnit, IUnits } from "../../types/units";
import {
  handleDeleteRequest,
  handleGetRequest,
  handlePatchRequest,
  handlePostRequest,
  Service,
} from "./";

function mapAccountEntry(accountEntry: any): IAccountEntry {
  const entry: IAccountEntry = {
    unitCode: accountEntry.unit_code,
    date: Moment(accountEntry.date),
    detail: accountEntry.detail,
  };

  if (accountEntry.hasOwnProperty("invoice_id")) {
    entry.invoiceID = accountEntry.invoice_id;
  }
  if (accountEntry.hasOwnProperty("amount_invoiced")) {
    entry.amountInvoiced = accountEntry.amount_invoiced;
  }
  if (accountEntry.hasOwnProperty("amount_paid")) {
    entry.amountPaid = accountEntry.amount_paid;
  }
  if (accountEntry.hasOwnProperty("account_balance")) {
    entry.accountBalance = accountEntry.account_balance;
  }

  return entry;
}

const newURLHostMapper = (host: string) => (url?: string) =>
  url ? new URL(url, host).href : undefined;
type URLHostMapper = ReturnType<typeof newURLHostMapper>;

function newDocumentMapper(host: string) {
  const urlMapper = newURLHostMapper(host);

  return (document: any): IDocument => ({
    name: document.name,
    friendlyName: document.friendly_name,
    modifyTime: Moment(document.modify_time),
    downloadURL: urlMapper(document.download_url),
  });
}

function newInvoiceMapper(urlMapper: URLHostMapper) {
  return (invoice: any): IInvoice => ({
    id: invoice.id,
    modifyDate: Moment(invoice.modify_date),
    downloadURL: urlMapper(invoice.download_url),
  });
}

function mapContactMessageToServer(message: ContactMessage) {
  return {
    sender_name: message.name,
    sender_email: message.email,
    sender_telephone: message.telephone,
    sender_message: message.body,
  };
}

function mapNewsItem(news: any): INews {
  return {
    id: news.news_id,
    propertyCode: news.property_code,
    date: Moment(news.post_time),
    text: news.text,
  };
}

function mapProperty(property: any): IProperty {
  return {
    code: property.code,
    search: property.search,
    name: property.name,
    managerName: property.manager_name,
    managerEmail: property.manager_email,
    managerPhone: property.manager_phone,
    siteInfo: property.site_info,
  };
}

export function mapTenant(tenant: any): ITenant {
  const mapAddress = (address: any) => ({
    line1: address.line1 || "",
    line2: address.line2 || "",
    line3: address.line3 || "",
    line4: address.line4 || "",
    postalCode: address.postal_code || "",
  });

  const mapContactDetails = (contactDetails: any) => ({
    address: mapAddress(contactDetails.address),
    phoneMobile: contactDetails.phone_mobile || undefined,
    phoneDay: contactDetails.phone_day || undefined,
    phoneEvening: contactDetails.phone_evening || undefined,
    emailMain: contactDetails.email_main || undefined,
    emailCC: contactDetails.email_cc || [],
  });

  return {
    code: tenant.code,
    search: tenant.search,
    name: tenant.name || undefined,
    contactDetails: mapContactDetails(tenant.contact_details),
    sendEmail: tenant.send_email,
    emailReceipts: tenant.email_receipts,
  };
}

export function mapTenantRoles(tenantRoles: any): ITenantRoles {
  return {
    editor: tenantRoles.editor || false,
  };
}

function mapUnit(unit: any): IUnit {
  return {
    code: unit.code,
    tenantCode: unit.tenant_code,
    propertyCode: unit.property_code,
    search: unit.search,
    address: {
      line1: unit.address.line1,
      line2: unit.address.line2,
      line3: unit.address.line3,
      line4: unit.address.line4,
      line5: unit.address.line5,
    },
    enabled: unit.enabled,
  };
}

function mapTenantToServer(tenant: Partial<ITenant>) {
  const filterObject = (obj: { [key: string]: any }) => {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    if (Object.keys(obj).length === 0) {
      return undefined;
    }
    return obj;
  };

  return (
    tenant &&
    filterObject({
      code: tenant.code,
      contact_details:
        tenant.contactDetails &&
        filterObject({
          address:
            tenant.contactDetails.address &&
            filterObject({
              line1: tenant.contactDetails.address.line1,
              line2: tenant.contactDetails.address.line2,
              line3: tenant.contactDetails.address.line3,
              line4: tenant.contactDetails.address.line4,
              postal_code: tenant.contactDetails.address.postalCode,
            }),
          phone_mobile: tenant.contactDetails.phoneMobile,
          phone_day: tenant.contactDetails.phoneDay,
          phone_evening: tenant.contactDetails.phoneEvening,
          email_main: tenant.contactDetails.emailMain,
          email_cc: tenant.contactDetails.emailCC,
        }),
      email_receipts: tenant.emailReceipts,
      name: tenant.name,
      search: tenant.search,
      send_email: tenant.sendEmail,
    })
  );
}

function mapUserProfile(profile: any): UserProfile {
  return {
    tenantCode: profile.tenant_code || "",
    username: profile.username || "",
    deleteTime: profile.delete_time ? Moment(profile.delete_time) : undefined,
    emailAddress: profile.email_address || "",
    readOnly: profile.read_only,
  };
}

export class PortalService extends Service {
  public deleteTenantUserProfile = (tenantCode: string, username: string) =>
    doDeleteTenantUserProfile(this.axios, tenantCode, username);
  public getDataVersion = () => doGetDataVersion(this.axios);
  public getDocument = (name: string) =>
    doGetDocument(this.axios, name, newDocumentMapper(this.host));
  public getInvoice = (id: string) =>
    doGetInvoice(this.axios, id, newURLHostMapper(this.host));
  public getProperty = (code: string) => doGetProperty(this.axios, code);
  public getTenant = (code: string) => doGetTenant(this.axios, code);
  public getTenantRoles = (code: string) => doGetTenantRoles(this.axios, code);
  public getUnit = (code: string) => doGetUnit(this.axios, code);

  public listAccountsEntriesForUnit = (unitCode: string) =>
    doListAccountsEntriesForUnit(this.axios, unitCode);
  public listDocumentsForProperty = (propertyCode: string) =>
    doListDocumentsForProperty(
      this.axios,
      propertyCode,
      newDocumentMapper(this.host)
    );
  public listNewsForProperty = (propertyCode: string) =>
    doListNewsForProperty(this.axios, propertyCode);
  public listTenants = () => doListTenants(this.axios);
  public listTenantUserProfiles = (tenantCode: string) =>
    doListTenantUserProfiles(this.axios, tenantCode);
  public listUnits = (codes?: Array<string>) => doListUnits(this.axios, codes);
  public sendManagerMessage = (msg: ContactMessage) =>
    doSendManagerMessage(this.axios, msg);

  public updateTenantAddress = (code: string, details: ITenantAddressDetails) =>
    doUpdateTenant(this.axios, code, {
      contactDetails: {
        address: details,
      },
    });
  public updateTenantContactDetails = (
    code: string,
    details: ITenantContactDetails
  ) => {
    return doUpdateTenant(this.axios, code, {
      contactDetails: {
        address: details.address,
        emailMain: details.emailMain,
        emailCC: details.emailCC,
        phoneDay: details.telephone && details.telephone.phoneDay,
        phoneEvening: details.telephone && details.telephone.phoneEvening,
        phoneMobile: details.telephone && details.telephone.phoneMobile,
      },
    });
  };
  public updateTenantEmail = (code: string, details: ITenantEmailDetails) =>
    doUpdateTenant(this.axios, code, {
      contactDetails: {
        emailCC: details.emailCC,
        emailMain: details.emailMain,
      },
      sendEmail: details.sendEmail,
      emailReceipts: details.emailReceipts,
    });
  public updateTenantTelephones = (
    code: string,
    details: ITenantTelephoneDetails
  ) =>
    doUpdateTenant(this.axios, code, {
      contactDetails: details,
    });
}

export type DataVersion = {
  lastImportTime: Moment.Moment;
  dataVersion: Moment.Moment;
};

const doGetDataVersion = (axios: AxiosInstance) =>
  handleGetRequest<DataVersion>(axios, `/v1/data_version`, (response) => ({
    lastImportTime: Moment(response.last_import),
    dataVersion: Moment(
      new Date(
        response.data_version.year,
        // Months measured from 0 to 11 in JS
        response.data_version.month-1,
        response.data_version.day
      )
    ),
  }));

const doGetDocument = (
  axios: AxiosInstance,
  name: string,
  mapper: (arg0: any) => IDocument
) =>
  handleGetRequest<IDocument>(axios, `/v1/${name}`, (document) =>
    mapper(document)
  );

const doGetInvoice = (
  axios: AxiosInstance,
  id: string,
  urlMapper: URLHostMapper
) =>
  handleGetRequest<IInvoice>(axios, `/v1/invoices/${id}`, (invoice) =>
    newInvoiceMapper(urlMapper)(invoice)
  );

const doGetProperty = (axios: AxiosInstance, code: string) =>
  handleGetRequest<IProperty>(axios, `/v1/properties/${code}`, (property) =>
    mapProperty(property)
  );

const doGetTenant = (axios: AxiosInstance, code: string) =>
  handleGetRequest<ITenant>(axios, `/v1/tenants/${code}`, (tenant) =>
    mapTenant(tenant)
  );

const doGetTenantRoles = (axios: AxiosInstance, code: string) =>
  handleGetRequest<ITenantRoles>(
    axios,
    `/v1/tenants/${code}/roles`,
    mapTenantRoles
  );

const doSendManagerMessage = (axios: AxiosInstance, message: ContactMessage) =>
  handlePostRequest<ReturnType<typeof mapContactMessageToServer>>(
    axios,
    `/v1/units/${message.unitCode}/email`,
    mapContactMessageToServer(message)
  );

const doDeleteTenantUserProfile = (
  axios: AxiosInstance,
  tenantCode: string,
  username: string
) =>
  handleDeleteRequest(
    axios,
    `/v1/tenants/${tenantCode}/profiles/${username}`,
    {},
    mapUserProfile
  );

const doUpdateTenant = (
  axios: AxiosInstance,
  code: string,
  data: Partial<ITenant>
) =>
  handlePatchRequest<Partial<ITenant>, ITenant>(
    axios,
    `/v1/tenants/${code}`,
    mapTenantToServer(data) || {},
    (tenant) => mapTenant(tenant)
  );

const doListAccountsEntriesForUnit = (axios: AxiosInstance, unitCode: string) =>
  handleGetRequest<Array<IAccountEntry>>(
    axios,
    `/v1/units/${unitCode}/account`,
    (response) =>
      response.account_entries
        ? response.account_entries.map(mapAccountEntry)
        : []
  );

const doListDocumentsForProperty = (
  axios: AxiosInstance,
  propertyCode: string,
  mapper: (arg0: any) => IDocument
) =>
  handleGetRequest<Array<IDocument>>(
    axios,
    `/v1/properties/${propertyCode}/documents`,
    (documents) => (documents.documents ? documents.documents.map(mapper) : [])
  );

const doListNewsForProperty = (axios: AxiosInstance, propertyCode: string) =>
  handleGetRequest<Array<INews>>(
    axios,
    `/v1/properties/${propertyCode}/news`,
    (news) => (news.news ? news.news.map(mapNewsItem) : [])
  );

const doListTenantUserProfiles = (axios: AxiosInstance, tenantCode: string) =>
  handleGetRequest<UserProfile[]>(
    axios,
    `/v1/tenants/${tenantCode}/profiles`,
    (resp) => (resp.user_profiles ? resp.user_profiles.map(mapUserProfile) : [])
  );

function doGetUnit(axios: AxiosInstance, code: string) {
  return new Promise<IUnit | void>((resolve, reject) => {
    return axios.get(`/v1/units/${code}`).then(
      (response: AxiosResponse<any>) => {
        response.data ? resolve(mapUnit(response.data)) : resolve();
      },
      (error: any) => {
        reject(new Error(error));
      }
    );
  });
}

function doListTenants(axios: AxiosInstance) {
  return new Promise<ITenant[]>((resolve, reject) => {
    return axios.get("/v1/tenants").then(
      (response: AxiosResponse<any>) => {
        response.data.tenants
          ? resolve(response.data.tenants.map(mapTenant))
          : resolve([]);
      },
      (error: any) => {
        reject(new Error(error));
      }
    );
  });
}

function doListUnits(axios: AxiosInstance, codes?: Array<string>) {
  return new Promise<IUnits>((resolve, reject) => {
    return axios.get("/v1/units").then(
      (response: AxiosResponse<any>) => {
        response.data.units
          ? resolve(response.data.units.map(mapUnit))
          : resolve([]);
      },
      (error: any) => {
        reject(new Error(error));
      }
    );
  });
}
