import {
  createStyles,
  makeStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { INews } from "../../types/news";
import ContentBox from "../../widgets/content-box";

interface INewsProp {
  news: Array<INews>;
}

const NewsItemStyles = (theme: Theme) =>
  createStyles({
    newsDate: {
      fontWeight: "bold",
    },
    newsItem: {
      display: "block",
    },
    newsText: {
      textAlign: "justify",
    },
  });

const NewsItem: React.FunctionComponent<
  { newsItem: INews } & WithStyles<typeof NewsItemStyles>
> = ({ classes, newsItem }) => {
  return (
    <li className={classes.newsItem}>
      <Typography
        align="right"
        gutterBottom
        variant="body2"
        classes={{ body2: classes.newsDate }}
      >
        {newsItem.date.format("D MMMM YYYY")}
      </Typography>
      <article className={classes.newsText}>
        <Typography variant="body2">{newsItem.text}</Typography>
      </article>
    </li>
  );
};

const StyledNewsItem = withStyles(NewsItemStyles)(NewsItem);

const useListStyles = makeStyles((theme: Theme) => ({
  newsList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,

    "& li:not(:last-child)::after": {
      borderBottom: "1px solid #aaa",
      content: '""',
      display: "block",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
}));

const NewsList: React.FunctionComponent<INewsProp> = ({ news }) => {
  const classes = useListStyles();

  return (
    <ul className={classes.newsList}>
      {news.map((item: INews, idx: number) => (
        <StyledNewsItem key={`news-item-${idx}`} newsItem={item} />
      ))}
    </ul>
  );
};

type WidgetProps = INewsProp & {
  propertyName: string;
};

const BaseUnitNewsWidget: React.FunctionComponent<WidgetProps> = ({
  news,
  propertyName,
}) => (
  <ContentBox title={`News for ${propertyName}`}>
    {news && news.length > 0 ? (
      <NewsList news={news} />
    ) : (
      <Typography variant="body2">
        There is no current news for your property.
      </Typography>
    )}
  </ContentBox>
);

export const UnitNewsWidget = BaseUnitNewsWidget;
